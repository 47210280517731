body, html {
  background-color: #f9fbfe !important;
  height: 100%;
}


.Mobile-Nav {
  display: none !important;
  background: #fff !important;
  border-bottom: 0.5px solid #D8D8D8;

}


.Sidebar {
  position: fixed;
  height: 100%;
  width: 230px;
  background-color: #fff;
  /* background-color: #5096FF; */
  display: flex;
  /* justify-content: center; */
  align-items: center;
  flex-direction: column;
  border-right: 0.5px solid #D8D8D8;
}

.Sidebar .Retailer {
  margin-top: 40px;
  border-radius: 100px;
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.Sidebar h5 {
  margin-top: 30px;
  text-align: center;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #aeaeae;
}

.Sidebar p {
  text-align: center;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #aeaeae;
}

.Sidebar ul {
  margin-top: 50px;
  width: 230px!important;
}

.Sidebar .active {
  background-color: #5096FF !important;
  /* background-color: rgba(230, 239, 253, 1) !important; */
  /* color: #6B6B6B !important; */
  color: #fff;
}

.Sidebar-Middle li {
  background-color: transparent !important;
  border: none;
  font-size: 14px;
  line-height: 21px;
  color: #aeaeae;
  font-weight: bold;
  padding-left: 60px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.Sidebar-Middle {
  flex:1;
}

.Sidebar-Bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 5px;
}

.Sidebar .Logo {
  width: 60px;
  margin-bottom: 5px;
  /* height: 38px; */
  /* margin-top: 60px; */
}

.Sidebar-Bottom div {
  display: flex;
}

.Sidebar-Bottom li {
  border: none;

  background-color: transparent !important;
  border: none;
  color: #aeaeae;
  font-weight: bold;
}

.App-Body {
  margin-left: 230px;
}

.App-Body h1 {
  font-weight: 700;
  font-size: 24px;
  line-height: 26px;
  color: #000;
}

.Body-Header {
  /* height: 60px; */
  background-color: #fff;
  border: 0.5px solid #D2D2D2;
  border-left: 0px;
  padding-left: 60px;
  padding-top: 18px;
  padding-bottom: 18px;
}

.Body-Header a {
  font-weight: 500;
  font-size: 14px;
  color: #aeaeae !important;
  text-decoration: none;
}

.Body-Header .active-header {
  color: #000 !important;
}


@media screen and (max-width: 992px) {
  .Mobile-Nav {
    display: block !important;
  }
  .App-Body {
    margin-left: 0px;
  }

  .Sidebar {
    display: none;
  }
}

.Action-Button {
  border-radius: 100px;
  border:none;
  height: 40px;
  width: 120px;
  font-size: 12px;
  color: #fff;
  background-color: #5096FF;
}

.Shelve {
  display: flex;
  flex-direction: row;
  /* flex-direction: row; */
  /* align-items: center; */
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 15px;
  padding-bottom: 15px;
  /* background-color: red; */
  margin-top: 15px;
  border-radius: 3px;
  border: 1px solid #D8D8D8;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.Shelve p {
  margin-top: 0px;
  margin-bottom: 0px;
}

input {
  padding:8px;
  border-radius: 3px;
  border: 1px solid #D8D8D8;
}

.time-picker {
  margin-top: 15px;
  margin-right: 15px;
  padding:8px;
  border-radius: 3px;
  border: 1px solid #D8D8D8;

}

.helper {
  border: 1px solid rgba(235, 232, 213, 1);
  padding:12px;
  border-radius: 3px;
  margin: auto;
  margin-top: 30px;
  margin-bottom: -30px;
  width: 90%;
  max-width: 600px;
  font-size: 12px;
  background-color: rgba(255, 253, 237, 1);
}

.small-helper {
  font-size: 12px;
  color: rgba(82, 93, 103, 1);
}

.Order-Page {
  background-color: red;
  /* top:0px;
  left:0px;
  position: absolute;
  background-color: red;
  z-index: 99;
  width: 100%;
  height: 100%; */
}

.text-area-input {
  padding: 12px;
  border: 1px solid #D8D8D8;
  border-radius: 3px;
  height: 240px;
  width: 100%;
  resize: none;
  margin-left: 25px;
}

.modal-content {
  border-radius: 8px !important;
  border: none !important;
  /* overflow-x: hidden !important; */
}


.modal-title {
  font-size: 18px !important;
}

.btn-close {
  font-size: 12px !important;
}

.modal-body {
  padding-left: 30px !important;
  padding-right: 30px !important;
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

.modal-footer {
  justify-content: flex-start !important;
}


.modal-footer button {
  border-radius: 100px !important;
  font-size: 16px !important;
  border:none !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
}


.modal-90w { max-width: 650px !important; }

/* SEARCH */
.searchWrapper span {

}

.css-2b097c-container {
  width: 100%;
}

.progress {
  height: 12px !important;
}

.progress-bar {
  background-color: #5096FF !important;
}


.color_block {
  height: 50px;
  /* margin-top:8px; */
  border-radius: 8px;
  /* width: 95%; */
  border: 1px solid #E4E4E4 !important;
  width: 100%;
}