.Info-div {
    /* margin-left: 125px; */
    margin: auto;
    margin-top: 60px;
    padding-left: 15px;
    padding-right: 15px;
    height: auto;;
    /* min-height: 1200px; */
    max-width: 800px;
    background-color: #fff;
    /* box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%); */
    border: 1px solid #D8D8D8;
    box-sizing: border-box;
    border-radius: 10px;


    padding: 35px;
}

.Info-div label {
    color: #000;
    font-weight: bold;
}

.Form-Input {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    /* background-color: red; */
}

.store-front label {
    width: 100% !important;
}

.store-front input {
    width: 100% !important;
}

.store-front .Form-Input {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}



.Form-Input label {
    width: 120px;
    font-weight: normal;
    /* margin-right: 60px; */
}

.Form-Input input {
    /* margin-top: 15px; */
    padding: 12px;
    border: 1px solid #D8D8D8;
    border-radius: 3px;
}

.Form-Input select {
    /* margin-top: 15px; */
    padding: 12px;
    border: 1px solid #D8D8D8;
    border-radius: 3px;
}

.Form-Input .input-field {
    flex: 1;
}

.Save {
    border: none;
    border-radius: 100px;
    background-color: #5096FF;
    color: #fff;
    height: 40px;
    width: 40px;
    font-size: 18px;
}


@media screen and (max-width: 1560px) {
    .Info-div {
        width: 85%;
    }
}

@media screen and (max-width: 992px) {
    .Info-div {
        width: 90%;
    }
}

h2 {
    font-style: normal;
    font-weight: 500;
    font-size: 18px !important;
    color: #000000;
}


.Form-Input select {
    flex: 1;
    margin-top: 0px;
}

textarea {
    padding: 12px;
    border: 1px solid #D8D8D8;
    border-radius: 3px;
    height: 240px;
    width: 100%;
    resize: none;
}



.search-wrapper {
    width: 100%;
}

.search-wrapper .chip {
    background-color:rgba(80, 150, 255, 1) !important;
    /* color: #000; */
}

.Create-user-modal select {
    width: 100%;
    padding:8px;
    border:1px solid #D8D8D8;
    border-radius: 3px;
}

.form-group input {
    position: relative;
    width: 100%;
}