.Filter-Container {
    margin: auto;
    padding-left:30px;
    /* padding-right:30px; */
    width: 85%;
    max-width: 1200px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 10px;
}

.Filter-Container select {
    /* padding: 12px; */
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 3px;
    padding-bottom: 3px;
    border: 1px solid #D8D8D8;
    border-radius: 100px;
}


.Chart-Container {
    margin: auto;
    padding:30px;
    width: 85%;
    max-width: 1200px;
    font-size: 14px;
    border-radius: 3px;
    border: 0.5px solid #D8D8D8;
    background-color: #fff;
}

.Chart-Header {
    /* background-color: red; */
    margin-bottom: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.Sales-Breakdown {
    margin-top: 30px;
    /* background-color: red; */
}

progress {
    width: 100%;
    height: 10px;
    /* -webkit-appearance: none;
    appearance: none; */
    /* background-color: rgba(232, 234, 237) !important; */
    /* border: none !important; */
}