.MuiPaper-root {
    margin: auto;
    margin-top: 60px;
    padding-left: 30px;
    padding-right: 30px;
    /* max-height: 1200px; */
    max-width: 1200px;
    font-size: 14px;
    background-color: transparent !important;
    box-shadow: none !important;
    border-radius: 3px;
    /* border: 0.5px solid #D8D8D8; */
}

.MuiTableHead-root {
    background-color: transparent !important;
}


.MuiTableCell-head {
    background-color: transparent !important;
}

.MuiToolbar-root {
    padding: 0px !important;
}

.MuiPaper-root h6 {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    color: #000000;
}


.MuiTableCell-head {
    font-size: 12px !important;
    color: rgba(149, 149, 149, 1) !important;
}

.MuiFormControl-root {
    /* background: #FFFFFF !important; */
    border-radius: 100px !important;
    /* border: 1px solid #D1D1D1 !important; */
    padding-top: 2.5px !important;
    padding-bottom: 2.5px !important;
    font-size: 12px !important;
}

.MuiSelect-root {
    font-size: 12px !important;
}


td .MuiFormControl-root {
    padding-left: 15px !important;
    padding-right: 15px !important;
}

.material-icons {
    color: #D1D1D1 !important
}

tr .MuiIconButton-root {
    /* display: none !important; */
}


.MuiInputBase-root {
    min-width:60px;
}

.MuiInput-underline:before {
    border-bottom: none !important;
}

@media screen and (max-width: 1560px) {
    .MuiPaper-root {
        width: 85%;
    }
}

@media screen and (max-width: 992px) {
    .MuiPaper-root {
        width: 90%;
    }
}

#menu-table-container th:nth-child(1) {
    /* display: none; */
    color: transparent !important;
}

.MuiInputLabel-formControl {
    padding-top: 5px !important;
    margin-bottom: 5px !important;
    padding-left: 8px !important;
    font-size: 12px !important;
}

.MuiFormControl-root {
    background-color: #fff !important;
    border: 1px solid #D8D8D8 !important; 
}

.MuiMenu-list {
    background-color: #fff !important;
    border: 1px solid #D8D8D8 !important; 
}