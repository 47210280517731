.Login {
    background-color: #f6f6f9;
    height: 100vh;
    padding-top: 120px;
}

.Login div {
    padding:60px;
    padding-left: 90px;
    padding-right: 90px;
    display: flex;
    flex-direction:column;
    align-items:center;
    max-width: 600px;
    margin: auto;
    background-color: #fff;
    border-radius: 8px;
}

.Login input {
    margin: 5px;
    width: 100%;
    padding-left: 15px;
    border-radius: 8px;
}


.Login select {
    height: 40px;
    margin: 5px;
    width: 100%;
    padding-left: 15px;
    border-radius: 8px;
    padding:8px;
    border: 1px solid #D8D8D8;
}

.Login button {
    margin-top: 5px;
    width: 100%;
    border-radius: 100px;
    border: none;
    height: 35px;
    background-color: #5096FF;
    color: #fff;
}

.Login button:hover {
    opacity: 0.7;
}

.Login h1 {
    font-size: 24px;
}